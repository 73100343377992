import { AmplitudeUtils } from './Amplitude.utils';


export class AmplitudeLogin {
    pageName: string;

    constructor(pageName: string) {
        this.pageName = pageName;
        this.initAmplitudeEvents();
    }

    initAmplitudeEvents(): void {
        const amplitudeUtils = new AmplitudeUtils(this.pageName);
        
        amplitudeUtils.logOnClick('#btn-facebook', 'login_facebook', {
            description: 'User clicks in fabebook login',
        });
        amplitudeUtils.logOnClick('#btn-google', 'login_google', {
            description: 'User clicks in google login',
        });
        amplitudeUtils.logOnClick('#btn-login', 'login_email', {
            description: 'User clicks in login by email',
        });
        amplitudeUtils.logOnClick('#btn-close', 'close_modal_login', {
            description: 'User clicks in close button on modal login',
        });
    }
}
