var GVCommon = require('../common/gv-common');
require('./amplitude-core');

var body = document.querySelector('body');

function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}


function isPwa() {
    return location.href.indexOf('#web-app') > -1 || sessionStorage.getItem('pwa-installed') === '1';
}

function isRedesign() {
    return !!body.dataset.redesign;
}

function isAmp() {
    return location.href.indexOf('?ampUrl=true') > -1;
}

function getVersion() {
    var pwa = isPwa() ? 'PWA_' : '';
    return isRedesign ? pwa + 'REDESIGN_NODE' : pwa + 'NODE';
}

function getUserEmail() {
    var email = GVCommon.data.userEmail;
    return email ? email.toLowerCase().trim() : '';
}

function noServiceFeeLabelOnCheckoutResume() {
    return getCookie('noServiceFeeLabelOnCheckoutResume') === 'true';
}

function insuranceSale() {
    return getCookie('insuranceSale') === 'true';
}

function smallDevicesAdjust() {
    return getCookie('smallDevicesAdjust') === 'true';
}

function removesTransitionHeader() {
    return getCookie('removesTransitionHeader') === 'true';
}

function widgetAnalyticsRemoved() {
    return getCookie('widgetAnalyticsRemoved') === 'true';
}

function busLayoutWithoutEmail() {
    return getCookie('busLayoutWithoutEmail') === 'true';
}

function getInsurancePriceGroup() {
    let abTestInsurancePrice = getCookie('insurancePriceGroupB');
    if (abTestInsurancePrice === "true" || abTestInsurancePrice === "false") {
        return abTestInsurancePrice;
    }

    return "null";
}

function searchResultImprovement() {
    return getCookie('searchResultImprovement') === 'true';
}

function cancelLinkRemoved() {
    return getCookie('cancelLinkRemoved') === 'true';
}

function newHeader() {
    return getCookie('newHeader') === 'true';
}

function linksOnCard() {
    return getCookie('linksOnCard') === 'true';
}

function checkoutResume() {
    return getCookie('checkoutResume') === 'true';
}

function readAmplitudeCookies(){
    try{
      var cookies = document.cookie.split(';');
      var c = cookies.find(function(p){
        return p && p.indexOf('amplitude_buson=')>=0
      });
    var cookieValue = c ? c.split('=')[1] : null;
      if(cookieValue){
        var cookie = JSON.parse(decodeURIComponent(cookieValue));
        return {
          deviceId:cookie.deviceId,
          userId:cookie.userId,
          sessionId:cookie.sessionId
        }
      }
    }catch(e){
      console.error('Error with amplitude cookies',e);
    }
    return {};
  }

  function storeAmplitudeCookies(deviceId,userId,sessionId){
    var cookieValue = JSON.stringify({
        deviceId:deviceId,
        userId:userId,
        sessionId:sessionId
    });
    document.cookie = 'amplitude_buson='+encodeURIComponent(cookieValue)+';path=/;max-age=3600';
  }
  var oldCookies = readAmplitudeCookies();
// Configurações Iniciais
window.amplitude.init(body.dataset.amplitudeKey, null, {
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
    batchEvents: true,
    deviceId:oldCookies.deviceId,
    userId:oldCookies.userId,
    sessionId:oldCookies.sessionId
},function(instance){
    if(!oldCookies.deviceId){
        storeAmplitudeCookies(instance.options.deviceId,instance.options.userId,instance.getSessionId());
    }
    if(oldCookies.sessionId){
        instance.setSessionId(oldCookies.sessionId);
      }
});

window.amplitude.setUserId(getUserEmail());

window.amplitude.setUserProperties({
    email: getUserEmail(),
    version: getVersion(),
    testAb: body.dataset['test-ab'] || false,
    amp: isAmp() ? 'true' : 'false',
    noServiceFeeLabelOnCheckoutResume: noServiceFeeLabelOnCheckoutResume(),
    insuranceSale: insuranceSale(),
    smallDevicesAdjust: smallDevicesAdjust(),
    removesTransitionHeader: removesTransitionHeader(),
    widgetAnalyticsRemoved: widgetAnalyticsRemoved(),
    insurancePriceGroupB: getInsurancePriceGroup(),
    searchResultImprovement: searchResultImprovement(),
    cancelLinkRemoved: cancelLinkRemoved(),
    newHeader: newHeader(),
    linksOnCard: linksOnCard(),
    checkoutResume: checkoutResume(),
    busLayoutWithoutEmail: busLayoutWithoutEmail(),
});

module.exports = {
    sendAmplitudeEvent: function (eventName, properties, group, testVersion) {
        if (!properties) {
            properties = {};
        }

        properties.url = window.location.href;
        properties.version = getVersion();
        if (testVersion) properties.testABDetalhes = testVersion;

        window.amplitude.logEventWithGroups(eventName, properties, group);
    },
    sendEvents: function(){
        window.amplitude.sendEvents();
    }
};
