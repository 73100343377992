import { AmplitudeUtils } from '../../../commons/ts/amplitude/Amplitude.utils';
import * as Cookies from 'js-cookie';
import { cookie } from 'request';
export class GVCookiesLgpdAlert {

    private LOCAL_STORAGE_KEY: string = 'acceptLgpdAlert';

    private containerSelector: string = '.gv-cookie-lgpd-alert-container';
    private mainSelector: string = '#gv-cookie-lgpd-alert-banner';
    private btnActiveSelector: string = '#gv-cookie-lgpd-alert-banner .cla-btn-activate';
    private btnFunctionalSelector: string = '#gv-cookie-lgpd-alert-banner .cla-btn-only-functional';

    private containerElement: HTMLElement = null;
    private mainElement: HTMLElement = null;
    private btnActivateElement: HTMLElement = null;
    private btnFunctionalElement: HTMLElement = null;

    private amplitude: AmplitudeUtils;
    private activateCallbacks: Function[] = [];

    constructor() {
        try {
            this.amplitude = new AmplitudeUtils();
            this.cacheDom();
            this.attachEvents();
            this.show();
        } catch(e) {
            console.error(e);
        }
    }

    private cacheDom(): void {
        this.containerElement = document.querySelector(this.containerSelector);
        this.mainElement = document.querySelector(this.mainSelector);
        this.btnActivateElement = document.querySelector(this.btnActiveSelector);
        this.btnFunctionalElement = document.querySelector(this.btnFunctionalSelector);
    }

    private attachEvents(): void {
        if(this.btnActivateElement) {
            this.btnActivateElement.addEventListener('click', () => this.closeLgdp());
        }
        if(this.btnFunctionalElement) {
            this.btnFunctionalElement.addEventListener('click', () => this.closeFunctional());
        }
    }

    private show(): void {
        const seeLgpdAlert = Cookies.getJSON('LGPD') || {};
        const alreadyChoosen: boolean = seeLgpdAlert.hasSeenLgpdAlert == true || localStorage.getItem(this.LOCAL_STORAGE_KEY) !== null;

        if (!alreadyChoosen){
            this.amplitude.sendEvent("show-cookie-lgpd", {tag: "lgpd"});
            this.containerElement.style.display = 'block';
            this.mainElement.style.display = 'block';
        }
    }

    private hide(): void {
        this.containerElement.style.display = 'none';
        this.mainElement.style.display = 'none';
    }

    addCallbackOnActivate(event: Function): void {
        this.activateCallbacks.push(event);
    }

    closeLgdp() : void {
        this.hide();
        const seeLgpdAlert = Cookies.getJSON('LGPD') || {};
        seeLgpdAlert.hasSeenLgpdAlert = true;
        Cookies.set('LGPD', seeLgpdAlert);
        localStorage.setItem(this.LOCAL_STORAGE_KEY, 'true');
        this.amplitude.sendEvent("accept-cookie-lgpd", {tag: "lgpd"});
        this.activateCallbacks.forEach((f: Function) => f());
    }

    closeFunctional() {
        this.hide();
        const seeLgpdAlert = Cookies.getJSON('LGPD') || {};
        seeLgpdAlert.hasSeenLgpdAlert = true;
        Cookies.set('LGPD', seeLgpdAlert);
        localStorage.setItem(this.LOCAL_STORAGE_KEY, 'true');
        localStorage.setItem('isCookieBlocked', 'true');
        this.amplitude.sendEvent("accept-functional-cookie-lgpd", {tag: "lgpd"});
        this.activateCallbacks.forEach((f: Function) => f());
    }
}
