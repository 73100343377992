var AmplitudeService = require('../../components/amplitude/amplitude-config');
var AMPLITUDE_EVENTS = require('../../components/amplitude/amplitude-events-names');

module.exports = {
    init: function () {
        this.cacheDom();
        this.sendAmplitudeEvents();
    },

    sendAmplitudeEvents: function () {
        if (!!this.$paginaHome)
            AmplitudeService.sendAmplitudeEvent(
                AMPLITUDE_EVENTS.HOME.PAGEVIEW.TITLE,
                {
                    description: AMPLITUDE_EVENTS.HOME.PAGEVIEW.DESCRIPTION,
                    lgpdBannerIsOpen: this.lgpdBannerIsOpen()
                },
                AMPLITUDE_EVENTS.HOME.GROUP
            );

        if (this.$btnPushNotification) {
            this.$btnPushNotification.addEventListener('click', function () {
                AmplitudeService.sendAmplitudeEvent(
                    AMPLITUDE_EVENTS.HOME.PUSH_NOTIFICATION_ACCEPT.TITLE,
                    {},
                    AMPLITUDE_EVENTS.HOME.GROUP
                );
            });
        }
    },

    lgpdBannerIsOpen: function () {
        return localStorage.getItem("acceptLgpdAlert") !== "true";
    },

    cacheDom: function () {
        this.$paginaHome = document.querySelector('.page-home');
    },
};
