/**
 * @description Descrições dos eventos enviados para o Amplitude
 */

module.exports = {
    HOME: {
        GROUP: { search: 'search' },
        PAGEVIEW: {
            TITLE: 'Pageview home',
            DESCRIPTION: 'Page home is loaded',
        },
        ORIGIN_SELECTION: {
            TITLE: 'origin selection',
            DESCRIPTION: 'Click on form search origin input',
        },
        DESTINATION_SELECTION: {
            TITLE: 'destination selection',
            DESCRIPTION: 'Click on form search destination input',
        },
        DEPARTURE_DATE_SELECTION: {
            TITLE: 'departure date selection',
            DESCRIPTION: 'Click on form search departure date input',
        },
        ARRIVAL_DATE_SELECTION: {
            TITLE: 'arrival date selection',
            DESCRIPTION: 'Click on form search arrival date input',
        },
        SEARCH_CLICK: {
            TITLE: 'Search - Click',
            DESCRIPTION: 'Click on form search submit input'
        },
        PUSH_NOTIFICATION_ACCEPT: {
            TITLE: 'accept_push_notification'
        },
        TAB_ONLY_GO: {
            TITLE: 'Tab_onlyGo',
            DESCRIPTION: 'Click in only go tab',
        },
        TAB_GO_BACK: {
            TITLE: 'Tab_Go_Back',
            DESCRIPTION: 'Click in go and back tab',
        },
        RADIO_ONLY_GO: {
            TITLE: 'Radio_onlyGo',
            DESCRIPTION: 'Click in only go radio button - desk',
        },
        RADIO_GO_BACK: {
            TITLE: 'Radio_Go_Back',
            DESCRIPTION: 'Click in only go radio button - desk',
        },
    },
};
