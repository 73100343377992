const HomeService = require('../../../../src/_v2/application/pages/home/home.service');
import { GVCookiesLgpdAlert } from '../commons/components/gv-cookie-lgpd-alert';
import { RulesAmplitudeHandler } from '../commons/ts/amplitude/rules-amplitude-handler';
import { AmplitudeLogin } from '../commons/ts/amplitude/amplitude-login';

export class HomeController {
    constructor() {
        try {
            HomeService.init();
        } catch (e) {
            console.log('Failed to load home service: ', e);
        }

        new GVCookiesLgpdAlert();
        new RulesAmplitudeHandler('Home');
        new AmplitudeLogin('Home');

        document.querySelectorAll('.js-input-datepicker').forEach((input: HTMLInputElement) => {
            const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            if(w < 1000 && this.iOS()) {
                input.addEventListener('touchend', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    input.style.transform = 'TranslateY(-10000px)'
                    input.focus();
                    setTimeout(function () { input.style.transform = 'none' }, 100);
                })
            } else if(w < 1000 && !this.iOS()) {
                input.addEventListener('focus', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    input.style.transform = 'TranslateY(-10000px)'
                    input.focus({preventScroll: true});
                    setTimeout(function () { input.style.transform = 'none' }, 1000);
                })
            }
        })
    }
    private iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
}

new HomeController();
