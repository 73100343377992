import { AmplitudeUtils } from './Amplitude.utils';

export class RulesAmplitudeHandler {
    pageName: string;
    logOnLoadPage: boolean;

    constructor(pageName: string, logOnLoadPage: boolean = false) {
        this.pageName = pageName;
        this.logOnLoadPage = logOnLoadPage;
        this.initAmplitudeEvents();
    }

    initAmplitudeEvents(): void {
        const amplitudeUtils = new AmplitudeUtils(this.pageName);
        if (this.logOnLoadPage)
            amplitudeUtils.logOnLoad(`Pageview page ${this.pageName}`, {});

        //icons sidebar and taskbar
        amplitudeUtils.logOnClick('.js-sidebar-amplitude', 'icon_menu_click', {
            description: 'User clicks in icon menu',
        });
        amplitudeUtils.logOnClick('.js-logo-amplitude', 'logo_sidebar_click', {
            description: 'User clicks in logo on sidebar',
        });
        amplitudeUtils.logOnClick('.js-logo-taskbar-amplitude', 'logo_taskbar_click', {
            description: 'User clicks in logo on taskbar',
        });
        amplitudeUtils.logOnClick('.js-login-button-taskbar-amplitude', 'login_button_taskbar_click', {
            description: 'User clicks in login button on taskbar',
        });
        amplitudeUtils.logOnClick('.js-login-sidebar-header-amplitude', 'login_sidebar_header_click', {
            description: 'User clicks in login on sidebar header',
        });

        // links do sidebar
        amplitudeUtils.logOnClick('.js-account-amplitude', 'clicked_account_sidebar', {
            description: 'User clicks in account link',
        });
        amplitudeUtils.logOnClick('.js-cancel-amplitude', 'clicked_cancel_sidebar', {
            description: 'User clicks in cancel link',
        });
        amplitudeUtils.logOnClick('.js-bus-station-amplitude', 'clicked_bus_station_sidebar', {
            description: 'User clicks in bus station link',
        });
        amplitudeUtils.logOnClick('.js-all-companies-amplitude', 'clicked_all_companies_sidebar', {
            description: 'User clicks in companies link',
        });
        amplitudeUtils.logOnClick('.js-offers-amplitude', 'clicked_offers_sidebar', {
            description: 'User clicks in offers link',
        });
        amplitudeUtils.logOnClick('.js-blog-amplitude', 'clicked_blog_sidebar', {
            description: 'User clicks in blog link',
        });
        amplitudeUtils.logOnClick('.js-attendance-amplitude', 'clicked_attendance_sidebar', {
            description: 'User clicks in attendance link',
        });


        // eventos taskbar
        amplitudeUtils.logOnClick('.taskbar-bus-station-header', 'clicked_bus-station_header', {
            description: 'user clicked at header bus-station link'
        })
        amplitudeUtils.logOnClick('.taskbar-all-companies-header', 'clicked_all-companies_header', {
            description: 'user clicked at header all-companies link'
        })
        amplitudeUtils.logOnClick('.taskbar-tickets-header', 'clicked_tickets_header', {
            description: 'user clicked at header tickets link'
        })
        amplitudeUtils.logOnClick('.taskbar-promotion-header', 'clicked_promotion_header', {
            description: 'user clicked at header promotion link'
        })
        amplitudeUtils.logOnClick('.taskbar-suport-header', 'clicked_suport_header', {
            description: 'user clicked at header suport link'
        })
        amplitudeUtils.logOnClick('.taskbar-blog-header', 'clicked_blog_header', {
            description: 'user clicked at header blog link'
        })
    }
}
